import * as React from "react"
import { Helmet } from "react-helmet"
import { SimpleLayout } from "../components/SimpleLayout"
import { HomeLanding } from "../components/HomeLanding"
import { HomeTeamSpecialists } from "../components/HomeTeamSpecialists"
import { HomePlans } from "../components/HomePlans"

const IndexPage = ({ data }) => {
  // const articles = data.recentArticles.nodes
  //const caseStudyArticles = data.recentCaseStudy.nodes
  return (
    <SimpleLayout>
      <Helmet htmlAttributes={{ lang: "pl" }}>
        <meta charSet="utf-8" />
        <title>OakTeams</title>
        <meta
          name="description"
          content="Oakteams - create your best software team"
        />
      </Helmet>
      <HomeLanding />
      <HomeTeamSpecialists />
      <HomePlans />
    </SimpleLayout>
  )
}

export default IndexPage

/*
{
    /*

    OLD INDEX PAGE

import { graphql } from "gatsby"
import { Layout } from "../components/Layout"
import { HomeVideo } from "../components/HomeVideo"
import { HomeWhatWeDo } from "../components/HomeWhatWeDo"
import { HomeAboutUs } from "../components/HomeAboutUs"
import { HomeTechnologies } from "../components/HomeTechnologies"
import { HomeProcess } from "../components/HomeProcess"
import { HomeCaseStudies } from "../components/HomeCaseStudies"
import { HomeNumbers } from "../components/HomeNumbers"
// import { RecentArticles } from "../components/RecentArticles"
import { HomeContactUs } from "../components/HomeContactUs"
import { Helmet } from "react-helmet";

<Layout>
      <Helmet htmlAttributes={{ lang: 'pl' }}>
        <meta charSet="utf-8"/>
        <title>OakTeams</title>
        <meta
          name="description"
          content="Oakteams - create your best software team"
        />
      </Helmet>
      <HomeVideo />
      <HomeAboutUs />
      <HomeWhatWeDo />
      <HomeTechnologies />
      <HomeProcess />
      <HomeCaseStudies articles={caseStudyArticles} />
      <HomeNumbers />
      {/* <RecentArticles home articles={articles} /> }
      <HomeContactUs />
    </Layout>
    
  }


  export const query = graphql`
  query CaseStudyHome {
    recentCaseStudy: allContentfulOakTeamsCaseStudy(
      sort: { fields: createdAt, order: DESC }
      limit: 8
    ) {
      nodes {
        id
        title
        thumbnail {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
        slug
      }
    }
  }
`

*/

// export const query = graphql`
//   query BlogPostsHome {
//     recentArticles: allContentfulOakTeamsBlogPost(
//       sort: { fields: createdAt, order: DESC }
//       limit: 8
//     ) {
//       nodes {
//         id
//         title
//         thumbnail {
//           gatsbyImageData(quality: 100, placeholder: BLURRED)
//         }
//         slug
//       }
//     }
//   }
// `
